import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";

import Half8Solid from "../icons/half-8-solid.svg";
import Half2Solid from "../icons/half-2-solid.svg";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title={`Privacy policy`} description={`Privacy policy`} />

      <div className="page-detail">
        <div className="hero">
          <div className="container-lg position-relative">
            <Half2Solid className="ico-svg ico-half-2-solid ico-right" />
            <Half8Solid className="ico-svg ico-half-8-solid ico-left" />
            <div className="row">
              <div className="col-10 col-md-8">
                <h1 className="hero__title">Privacy policy</h1>
              </div>
            </div>
          </div>
          <div className="hero__text-scroll hero__text-scroll--classic">
            <div className="text-scroll" aria-hidden="true">
              <div className="text-scroll__wrap">
                <span className="text-uppercase privacy">Privacy</span>
              </div>
              <div className="text-scroll__wrap text-scroll__wrap--repeat">
                <span className="text-uppercase privacy">Privacy</span>
              </div>
            </div>
          </div>
        </div>

        <div className="page-detail__text">
          <div className="container-lg pb-4">
            <div className="row">
              <div className="col-12 col-md-10 offset-md-1  col-lg-8 offset-lg-2">
                <p>
                  Ariadne Digital S.r.l., proprietaria del presente sito web, si impegna a
                  proteggere la privacy dei propri utenti e a tutelare i dati personali raccolti nel
                  rispetto del "Codice in materia di protezione dei dati personali" (D.Lgs. 196/03).
                </p>
                <p>
                  L'informativa è resa ai sensi dell'art. 13 del D.Lgs. 30 giugno 2003, n. 196 e del
                  Regolamento Europeo in materia di Protezione dei Dati Personali (2016/679) per gli
                  utenti che consultano e interagiscono questo sito e usufruiscono dei servizi
                  forniti.
                </p>
                <p>
                  L'informativa è resa solo per il sito "www.intranet8020.it" (d'ora in avanti "Sito
                  Intranet 80.20"), e non anche per altri siti web eventualmente raggiunti
                  dall'utente tramite link.
                </p>
                <h2>Finalità del trattamento</h2>
                <p>
                  <strong>Titolare del trattamento</strong>
                </p>
                <p>
                  Ariadne Digital Srl con sede legale in Via Visconti di Modrone, 11 - 20122 -
                  MILANO, Green Team Società Cooperativa con sede legale in via della Liberazione,
                  6/c - 40128 - Bologna Intranet Management srl con sede legale Vocabolo
                  Sant'Egidio, 17 - 02047 Torri in Sabina (RI)
                </p>
                <p>
                  (le “Società” o il “Titolare”) garantisce il rispetto della disciplina in materia
                  di protezione dei dati personali fornendo le seguenti informazioni circa il
                  trattamento dei dati comunicati o comunque raccolti nel corso della navigazione
                  sul presente sito.
                </p>
                <h2>Dati trattati, finalità e basi giuridiche del trattamento</h2>
                <p>
                  <strong>Dati generati dall'accesso al sito</strong>
                </p>
                <p>
                  I sistemi informatici e le procedure software preposte al funzionamento di questo
                  sito acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la
                  cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet.
                  Questi dati (quali ad esempio nomi di dominio, indirizzi IP, sistema operativo
                  utilizzato, tipo di device di browser utilizzati per la connessione) non sono
                  accompagnati da alcuna informazione personale aggiuntiva e vengono utilizzati per:
                </p>
                <ul>
                  <li>ricavare informazioni statistiche anonime sull'uso del sito;</li>
                  <li>gestire esigenze di controllo delle modalità di utilizzo dello stesso,</li>
                  <li>accertare responsabilità in caso di ipotetici reati informatici.</li>
                </ul>
                <p>
                  La base giuridica che legittima il trattamento di tali dati è la necessità di
                  rendere utilizzabili le funzionalità del sito a seguito dell'accesso dell'utente.
                </p>
                <p>
                  <strong>Dati forniti volontariamente dall'utente</strong>
                </p>
                <p>
                  I dati personali forniti dall'utente tramite form sono raccolti e trattati per le
                  seguenti finalità:
                </p>
                <ul>
                  <li>per lo svolgimento delle attività di relazione con il cliente</li>
                  <li>
                    per finalità amministrative e per l'adempimento di obblighi di legge quali ad
                    esempio quelli di natura contabile, fiscale, o per dar corso a richieste
                    dell'autorità giudiziaria;
                  </li>
                  <li>
                    per l'invio periodico, tramite e-mail, di newsletter di aggiornamento sulle
                    nostre attività, materiale informativo, inviti ad eventi;
                  </li>
                  <li>
                    nel caso di invio di curriculum vitae, esclusivamente per finalità di selezione.
                  </li>
                </ul>
                <p>
                  La base giuridica che legittima il trattamento è l'esecuzione di un contratto di
                  cui l'interessato è parte o l'esecuzione di misure precontrattuali adottate su
                  richiesta dello stesso. Nei casi espressamente indicati la base giuridica è il
                  consenso liberamente fornito dall'interessato.
                </p>
                <p>
                  <strong>Modalità del trattamento</strong>
                </p>
                <p>
                  <strong>Dati forniti volontariamente dall'utente</strong>
                </p>
                <p>
                  Il trattamento dei dati avrà luogo con modalità sia automatizzate che manuali, e
                  nel rispetto delle regole di riservatezza e di sicurezza previste dalla legge. La
                  raccolta di queste informazioni avviene unicamente attraverso i form presenti nel
                  sito. I dati saranno trattati solo dagli incaricati e dai Responsabili del
                  trattamento nominati da Ariadne Digital S.r.l., Green Team Soc Coop e Intranet
                  Management srl in qualità di Titolare del trattamento. Nessun altro dato personale
                  degli utenti viene acquisito dal sito Intranet 80.20. Nessun dato personale degli
                  utenti verrà in alcun modo ceduto a terzi.
                </p>
                <p>
                  <strong>Dati di navigazione</strong>
                </p>
                <p>
                  Alcuni dati personali sono raccolti in modo implicito con l'uso dei protocolli di
                  comunicazione di Internet durante la normale navigazione. Appartengono a questa
                  categoria gli indirizzi IP o i nomi a dominio dei computer usati dagli utenti per
                  collegarsi, il metodo usato per sottoporre le richieste al server, e altri
                  parametri relativi al sistema operativo e al sistema informatico dell'utente.
                  Questi dati sono utilizzati in modo anonimo e solo per fini statistici in
                  relazione all'utilizzo del sito.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
